import React from "react";
import { Modal as AntModal } from "antd";
import styles from "./index.module.scss";

export type Props = {
  visible: boolean;
  onCancel?: () => void;
  width?: string;
  afterClose?: () => void;
  centered?: boolean;
  children?: React.ReactNode;
};

export const Modal: React.FC<Props> = ({
  children,
  visible,
  onCancel,
  width,
  afterClose,
  centered = false,
}) => {
  return (
    <>
      <AntModal
        maskStyle={{
          backdropFilter: "blur(4px)",
          background: "rgba(28, 31, 35, 0.7)",
        }}
        width={width}
        title={null}
        closeIcon={null}
        open={visible}
        centered={centered}
        onCancel={onCancel}
        afterClose={afterClose}
        className={styles.modal}
        footer={null}
      >
        {children}
      </AntModal>
    </>
  );
};
