import { parse } from "date-fns";
import { formatDate } from "@utils/date-time/common";
import { useHydrationSafeDateToUtc } from "@components/hooks/use-hydration-safe-date";
import styles from "./index.module.scss";

export type Props = {
  upcomingLessonTitle: string;
  date: string;
  onClick: () => void;
};

const months = [
  "Janvāris",
  "Februāris",
  "Marts",
  "Aprīlis",
  "Maijs",
  "Jūnijs",
  "Jūlijs",
  "Augusts",
  "Septembris",
  "Oktobris",
  "Novembris",
  "Decembris",
];

export const UpcomingLessonBadge = ({
  upcomingLessonTitle,
  date,
  onClick,
}: Props) => {
  const dateOfStart = parse(date, "dd.MM.yyyy", new Date());
  const formatTime = useHydrationSafeDateToUtc(dateOfStart);
  const monthIndex = Number(formatDate(formatTime, "M")) - 1;
  const day = formatDate(formatTime, "d");
  return (
    <div className={styles.dateWithText}>
      <div className={styles.titleWrapper}>
        <p className={styles.startText}>{upcomingLessonTitle}</p>
      </div>
      <button
        onClick={onClick}
        className={styles.dateWrapper}
        suppressHydrationWarning
      >
        {`${day}. ${months[monthIndex]} plkst. 18:00`}
      </button>
    </div>
  );
};
