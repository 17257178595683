import { Skeleton } from "antd";
import Image from "next/legacy/image";
import { Button } from "@components/atoms/Button";
import { Modal } from "@components/atoms/Modal";
import { Banner } from "@components/molecules/Banner";
import styles from "./index.module.scss";

export interface Props {
  loading: boolean;
  event?: {
    available: boolean;
    password?: string;
    onJoin?: () => void;
  };
  onBack: () => void;
  isModalVisible: boolean;
  onCancel: () => void;
}

export const JoinCodingEvent = ({
  loading,
  event,
  onBack,
  isModalVisible,
  onCancel,
}: Props) => {
  return (
    <>
      <Modal visible={isModalVisible} onCancel={onCancel}>
        <div
          className={styles.joinCodingEventWrapper}
          data-testid="join-intro-lesson-modal"
        >
          <Banner
            title="Pievienoties nodarbībai 🧑‍💻"
            content={
              loading ? (
                <Skeleton active />
              ) : event?.available ? (
                <>
                  <p>
                    Prieks Tevi satikt! Lai pievienotos tiešsaistes nodarbībai,
                    spied uz pogas zemāk un nonāksi Zoom.
                  </p>
                  <p>
                    🔒⚠️ Ja Tev tiek vaicāta parole, ievadi{" "}
                    <span className={styles.highLight}>{event?.password}</span>
                  </p>
                </>
              ) : (
                <>
                  <p>Izskatās, ka nodarbība nav pieejama.</p>
                </>
              )
            }
            extra={[
              loading || event?.available ? (
                <Button
                  key="join"
                  disabled={loading}
                  onClick={() => {
                    if (event?.available) {
                      event.onJoin?.();
                    }
                  }}
                >
                  Pievienoties
                </Button>
              ) : (
                <Button key="back" disabled={loading} onClick={onBack}>
                  Uz sākumu
                </Button>
              ),
            ]}
          />
        </div>
        <div className={styles.close} onClick={onCancel}>
          <Image
            src={"/svg/close.svg"}
            alt="close icon"
            width={28}
            height={28}
          />
        </div>
      </Modal>
    </>
  );
};
