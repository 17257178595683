import { useEffect, useState } from "react";
import { GoBackButton } from "@components/atoms/GoBackButton";
import { HeaderLogo } from "@components/atoms/HeaderLogo";
import { formatSeconds } from "@utils/date-time/common";
import styles from "./index.module.scss";

export interface Props {
  remainingTime: number;
  onExpired: () => void;
  currentQuestionIndex: number;
  exitQuiz: string;
}

export const NavMenuForQuiz = ({
  remainingTime: initialRemainingTime,
  onExpired,
  currentQuestionIndex,
  exitQuiz,
}: Props) => {
  const [remainingTime, setRemainingTime] = useState(initialRemainingTime);

  useEffect(() => {
    if (remainingTime <= 1) {
      onExpired();
      return;
    }
    const interval = setInterval(
      () => setRemainingTime(remainingTime - 1),
      1000,
    );
    return () => clearInterval(interval);
  }, [remainingTime, onExpired]);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <HeaderLogo link="/" />
        </div>
        <div className={styles.timerWrapper}>
          <div className={styles.timerText}>
            Jautājums&nbsp;{" "}
            <span className={styles.timerTextQusetion}>
              {" "}
              {currentQuestionIndex + 1} no 10{" "}
            </span>{" "}
          </div>
          <div className={styles.timer}>
            {" "}
            {formatSeconds(remainingTime, "mm:ss")}
          </div>
          <div className={styles.exitQuiz}>
            <GoBackButton
              linkTo={exitQuiz}
              text={`Iziet no testa`}
              showArrowIcon={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
