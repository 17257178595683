import { z } from "zod";

export const ClickEventSchema = z.object({
  name: z.string(),
  pathname: z.string(),
  metadata: z.record(z.string()).optional(),
});

export const ServerSideClickEventSchema = z.intersection(
  ClickEventSchema,
  z.object({
    accountId: z.string(),
  }),
);

export const ServerSideEventSchema = z.object({
  name: z.string(),
  metadata: z.record(z.string()),
  accountId: z.string(),
});
