import React, { Fragment } from "react";
import Bugsnag, { OnErrorCallback } from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from "@bugsnag/plugin-react";
import {
  clientSideEnvVariable,
  requireClientSideEnvVariable,
} from "src/client-side-env";
import { getAccountId } from "@domain/account/cookies/csr";

const IS_BROWSER = typeof window !== "undefined";
const API_KEY = clientSideEnvVariable("BUGSNAG_API_KEY");

let ErrorBoundary: BugsnagErrorBoundary | undefined;

if (IS_BROWSER && API_KEY && !Bugsnag.isStarted()) {
  Bugsnag.start({
    apiKey: API_KEY,
    appVersion: requireClientSideEnvVariable("GIT_COMMIT"),
    plugins: [new BugsnagPluginReact()],
  });
  ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
}

const Boundary = ({ children }: { children: React.ReactNode }) => {
  if (ErrorBoundary) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }
  return <Fragment>{children}</Fragment>;
};

const logError = (e: string | Error | unknown) => {
  if (!IS_BROWSER || !API_KEY) {
    // eslint-disable-next-line no-console
    console.error(e);
    return;
  }
  const onErrorCallback: OnErrorCallback = (it) => it.setUser(getAccountId());
  if (e && e instanceof Error && e.stack && e.message) {
    Bugsnag.notify(e, onErrorCallback);
    return;
  }
  Bugsnag.notify(`Error logged: ${JSON.stringify(e)}`, onErrorCallback);
};

const leaveBreadcrumb = ({
  message,
  metadata,
}: {
  message: string;
  metadata?: { [key: string]: unknown };
}) => {
  if (!IS_BROWSER || !API_KEY) {
    // eslint-disable-next-line no-console
    console.log({ message, metadata });
    return;
  }
  Bugsnag.leaveBreadcrumb(message, metadata);
};

export { Boundary as ErrorBoundary, logError, leaveBreadcrumb };
