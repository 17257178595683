import React, { useContext, createContext, useState, FC } from "react";
import { useAddPersonalInformation } from "@domain/account/csr/useAddGenderDateOfBirth";
import { FormBeforeJoinCodingEvent } from "@components/organisms/FormBeforeJoinCodingEvent";
import { DateOfBirthFormOutput } from "@components/molecules/DateOfBirthForm";
import { trackEvents } from "@domain/analytics/csr";
import { JoinCodingEventContainer } from "./JoinCodingEventContainer";

type ContextType = {
  showJoinCodingEventModal: () => void;
};

export const CodingEventContext = createContext<ContextType>({
  showJoinCodingEventModal: () => null,
});

export const useJoinCodingEvent = () =>
  useContext<ContextType>(CodingEventContext);

type State = {
  joinEventModal: boolean;
  personalDetailsModal: boolean;
  personalInformationPresent: boolean;
};

export const CodingEventContextProvider: FC<{
  children?: React.ReactNode;
  accountId: string;
  personalInformationPresent: boolean;
}> = ({ children, accountId, personalInformationPresent }) => {
  const [state, setState] = useState<State>({
    joinEventModal: false,
    personalDetailsModal: false,
    personalInformationPresent,
  });
  const [addPersonalInformation] = useAddPersonalInformation();
  const onClose = () => {
    setState({ ...state, personalDetailsModal: false, joinEventModal: false });
  };
  const onFinish = async (values: DateOfBirthFormOutput) => {
    await addPersonalInformation({
      variables: {
        input: {
          dateOfBirth: values.date,
        },
      },
      onCompleted: () => {
        trackEvents({
          accountId,
          name: "pre-intro-course-rating",
          metadata: {
            rating: values.question.toString(),
          },
        });
        setState({
          ...state,
          personalDetailsModal: false,
          joinEventModal: true,
          personalInformationPresent: true,
        });
      },
    });
  };
  return (
    <CodingEventContext.Provider
      value={{
        showJoinCodingEventModal: () => {
          setState({
            ...state,
            personalDetailsModal: !state.personalInformationPresent,
            joinEventModal: state.personalInformationPresent,
          });
        },
      }}
    >
      {children}
      <FormBeforeJoinCodingEvent
        isModalVisible={state.personalDetailsModal}
        onCancel={onClose}
        onSubmit={onFinish}
      />
      <JoinCodingEventContainer
        isModalVisible={state.joinEventModal}
        onCancel={onClose}
      />
    </CodingEventContext.Provider>
  );
};
