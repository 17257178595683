import {
  publishFacebookEvent,
  publishGoogleAnalyticsEvent,
} from "@integration/analytics";
import { trackRegistration as notifyGoogleAds } from "@integration/google-ads/csr";

export const onRegistration = () => {
  publishFacebookEvent({ event: "registration" });
  publishGoogleAnalyticsEvent({
    eventCategory: "accounts",
    eventAction: "registration",
  });
  notifyGoogleAds();
};

export const onTaskSubmission = () => {
  // deprecated
  publishFacebookEvent({ event: "TDK_majasdarbi_iesniegti" });
  // deprecated
  publishGoogleAnalyticsEvent({
    eventCategory: "TDK",
    eventAction: "Majasdarbi iesniegti",
  });
  publishFacebookEvent({ event: "assignment-completed" });
  publishGoogleAnalyticsEvent({
    eventCategory: "assignments",
    eventAction: "assignment-completed",
  });
};

export const onFirstCodingEventParticipation = () => {
  publishFacebookEvent({ event: "TDK_pirmais_video_zvans" });
  publishGoogleAnalyticsEvent({
    eventCategory: "TDK",
    eventAction: "Pirmais video zvans",
  });
};
