import { useState, useEffect } from "react";
import { dateToUtc } from "@utils/date-time/common";

export const useHydrationSafeDateAsNumber = (
  date: Date | string | number | undefined | null,
) => {
  const [safeDate, setSafeDate] = useState(0);

  useEffect(() => {
    setSafeDate(date ? Number(new Date(date)) : Number(new Date()));
  }, [date]);

  return safeDate;
};

export const useHydrationSafeDateToUtc = (
  date: Date | string | number | undefined | null,
) => {
  const [safeDate, setSafeDate] = useState(0);

  useEffect(() => {
    setSafeDate(
      date ? Number(dateToUtc(new Date(date))) : Number(dateToUtc(new Date())),
    );
  }, [date]);

  return safeDate;
};
