import { posthog } from "posthog-js";
import { cio } from "@integration/customer-io/csr";
import {
  ServerSideClickEventSchema,
  ServerSideEventSchema,
} from "../type-schema";
import { ClickEvent, ServerSideEvent } from "../types";
import { axios } from "./axios";

export const trackEvents = async (event: ServerSideEvent) => {
  await axios.post("/events", ServerSideEventSchema.parse({ ...event }));
};

export const trackClick = async (accountId: string, event: ClickEvent) => {
  cio.track({
    eventName: "click",
    metadata: { name: event.name, ...event.metadata },
  });
  posthog.capture("click", { name: event.name, ...event.metadata });
  await axios.post(
    "/clicks",
    ServerSideClickEventSchema.parse({ accountId, ...event }),
  );
};
