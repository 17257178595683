import Image from "next/legacy/image";
import Link from "next/link";
import styles from "./index.module.scss";

export interface Props {
  email?: string;
}

export const UserProfile = ({ email }: Props) => {
  return (
    <Link href={"/my-account"}>
      <div className={styles.wrapper}>
        <div className={styles.avatarWrapper}>
          <Image
            src={"/svg/avatar.svg"}
            alt={"avatar image"}
            height={16}
            width={16}
          />
        </div>
        <div className={styles.account} data-testid="user-profile-badge-title">
          {email?.toLowerCase() || "Uzstādījumi"}
        </div>
      </div>
    </Link>
  );
};
