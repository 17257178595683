import { ReactNode } from "react";
import styles from "./index.module.scss";

export interface Props {
  title: string;
  content: ReactNode | string;
  extra?: ReactNode[];
}

export const Banner = ({ title, content, extra }: Props) => {
  return (
    <div className={styles.nextWrapper}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.description}>{content}</div>
      {extra}
    </div>
  );
};
