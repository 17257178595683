import { Modal } from "@components/atoms/Modal";
import {
  DateOfBirthFormOutput,
  DateOfBirthForm,
} from "@components/molecules/DateOfBirthForm";

export interface Props {
  isModalVisible: boolean;
  onCancel: () => void;
  onSubmit: (values: DateOfBirthFormOutput) => void;
}

export const FormBeforeJoinCodingEvent = ({
  isModalVisible,
  onCancel,
  onSubmit,
}: Props) => {
  return (
    <>
      <Modal width="600px" visible={isModalVisible}>
        <div className="client-modal">
          <DateOfBirthForm onSubmit={onSubmit} onCancel={onCancel} />
        </div>
      </Modal>
    </>
  );
};
