type ClientSideEnvKey =
  | "DEV_ENV"
  | "RELEASE_VERSION"
  | "GTM_ID"
  | "FB_PIXEL_IDS"
  | "BUGSNAG_API_KEY"
  | "GA_TRACKING_ID"
  | "LINKEDIN_REDIRECT_BASE_URL"
  | "CUSTOMER_IO_SITE_ID"
  | "GIT_COMMIT"
  | "POSTHOG_KEY"
  | "POSTHOG_HOST";

interface VariableEntry {
  key: ClientSideEnvKey;
  value: () => string | undefined;
  defaultValue: () => string | undefined;
}

/*
    Important thing to note - process.env is not a simple variable where you can do dynamic things like process.env['X'] or ...process.env
    Therefore all the variables must be mapped in the static hardcode way
*/
const entries: VariableEntry[] = [
  {
    key: "DEV_ENV",
    value: () => process.env.NEXT_PUBLIC_DEV_ENV,
    defaultValue: () => "false",
  },
  {
    key: "RELEASE_VERSION",
    value: () => process.env.NEXT_PUBLIC_RELEASE_VERSION,
    defaultValue: () => "unknown",
  },
  {
    key: "GTM_ID",
    value: () => process.env.NEXT_PUBLIC_GTM_ID,
    defaultValue: () => undefined,
  },
  {
    key: "FB_PIXEL_IDS",
    value: () => process.env.NEXT_PUBLIC_FB_PIXEL_IDS,
    defaultValue: () => undefined,
  },
  {
    key: "BUGSNAG_API_KEY",
    value: () => process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    defaultValue: () => undefined,
  },
  {
    key: "GA_TRACKING_ID",
    value: () => process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    defaultValue: () => undefined,
  },
  {
    key: "LINKEDIN_REDIRECT_BASE_URL",
    value: () => process.env.LINKEDIN_REDIRECT_BASE_URL,
    defaultValue: () => undefined,
  },
  {
    key: "CUSTOMER_IO_SITE_ID",
    value: () => process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID,
    defaultValue: () => undefined,
  },
  {
    key: "GIT_COMMIT",
    value: () => process.env.NEXT_PUBLIC_RENDER_GIT_COMMIT,
    defaultValue: () => "unknown",
  },
  {
    key: "POSTHOG_KEY",
    value: () => process.env.NEXT_PUBLIC_POSTHOG_KEY,
    defaultValue: () => "unknown",
  },
  {
    key: "POSTHOG_HOST",
    value: () => process.env.NEXT_PUBLIC_POSTHOG_HOST,
    defaultValue: () => "unknown",
  },
];

export const clientSideEnvVariable = (
  key: ClientSideEnvKey,
): string | undefined => {
  const entry = entries.find((it) => it.key === key);
  if (!entry) {
    throw new Error(`Could not get entry for env variable ${key}`);
  }
  return entry.value() || entry.defaultValue();
};

export const requireClientSideEnvVariable = (key: ClientSideEnvKey): string => {
  const value = clientSideEnvVariable(key);
  if (!value) {
    throw new Error(`Could not get entry for env variable ${key}`);
  }
  return value;
};
