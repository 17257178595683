import { useQuery, gql } from "@apollo/client";
import { useRouter } from "next/router";
import { JoinCodingEvent } from "@components/organisms/JoinCodingEvent";
import { onFirstCodingEventParticipation } from "@domain/analytics";
import { shouldSkipRequests } from "@integration/account-api/page-request-policy";
import { client } from "@integration/next-account-api";

type NearestCodingEventData = {
  account: {
    anyEventJoined: boolean;
  };
  event?: {
    id: string;
    available: boolean;
    password?: string;
  };
};

type Props = {
  isModalVisible: boolean;
  onCancel: () => void;
};

export const JoinCodingEventContainer = ({
  isModalVisible,
  onCancel,
}: Props) => {
  const router = useRouter();
  const { data, loading } = useQuery<NearestCodingEventData>(
    gql`
      query {
        account: fetchAccount {
          id
          anyEventJoined
        }
        event: fetchNearestCodingEvent {
          id
          available
          password
        }
      }
    `,
    { client, skip: shouldSkipRequests() },
  );
  const event = data?.event;
  return (
    <JoinCodingEvent
      isModalVisible={isModalVisible}
      onCancel={onCancel}
      loading={loading}
      event={
        event?.available && event?.password
          ? {
              available: event.available,
              password: event.password,
              onJoin: () => {
                const { available } = event;
                if (!available) {
                  return;
                }
                if (!data?.account.anyEventJoined) {
                  onFirstCodingEventParticipation();
                }
                window.open(
                  `${window.location.protocol}//${window.location.host}/coding-events/join`,
                  "_blank",
                );
              },
            }
          : {
              available: false,
            }
      }
      onBack={() => router.push("/")}
    />
  );
};
