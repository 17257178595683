import axios from "axios";
import { logError } from "@integration/errors/bugsnag/csr";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    logError(error);
    return Promise.reject(error);
  },
);

export { axios };
