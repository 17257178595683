export const onPageView = () => {
  const { gtag, location } = window;
  if (!gtag || location.hostname !== "app.codelex.io") {
    return;
  }
  gtag("config", "AW-10790112108");
};

export const trackRegistration = () => {
  const { gtag } = window;
  if (!gtag || location.hostname !== "app.codelex.io") {
    return;
  }
  gtag("event", "conversion", {
    send_to: "AW-10790112108/1JgLCOObg6oZEOyWkJko",
  });
};
