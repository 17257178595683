import {
  format as _format,
  differenceInDays as differenceInDaysFns,
  differenceInHours as differenceInHoursFns,
  differenceInMinutes as differenceInMinutesFns,
  differenceInMilliseconds as differenceInMillisecondsFns,
  endOfDay,
  subYears,
  parse,
  addSeconds,
  intervalToDuration,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { parse as parseISO, toSeconds } from "iso8601-duration";

export const DEFAULT_MOMENT_LOCALE = "lv-LV";
export const PICKER_DATE_FORMAT = "yyyy-MM-dd";
export const defaultPickerValueFormat = "yyyy/MM/dd";
export const nativeDateFormat = "dd.MM.yyyy";
export const nativeTimeFormat = "HH:mm";

export const formatDate = (date: number | Date | null, finalFormat: string) => {
  if (date === null) {
    return "";
  }
  return _format(date, finalFormat);
};

export const formatDateNative = (date: number | Date) => {
  return _format(date, nativeDateFormat);
};

export const formatTimeNative = (date: number | Date) => {
  return _format(date, nativeTimeFormat);
};

export const formatDateReadable = (date: number | Date) => {
  return _format(date, "MMM do yyyy");
};

export const parseToDefaultPickerValue = (date: string) => {
  return parse(date, defaultPickerValueFormat, new Date());
};

export const formatSeconds = (time: number, finalFormat: string) => {
  return _format(addSeconds(new Date(0), time), finalFormat);
};

export const dateToUtc = (date: Date | string | number) => {
  return zonedTimeToUtc(date, `UTC`);
};

export const formatDateToUtc = (
  date: Date | string | number,
  finalFormat: string,
) => {
  return _format(zonedTimeToUtc(date, `UTC`), finalFormat);
};

export const formatDateToUtcNative = (date: Date | string | number) => {
  return _format(zonedTimeToUtc(date, `UTC`), nativeDateFormat);
};

export const differenceInDays = (
  dateLeft: Date | number,
  dateRight: Date | number,
) => {
  return differenceInDaysFns(dateLeft, dateRight);
};

export const differenceHoursOnly = (
  dateLeft: Date | number,
  dateRight: Date | number,
) => {
  return differenceInHoursFns(dateLeft, dateRight) % 24;
};

export const differenceMinutesOnly = (
  dateLeft: Date | number,
  dateRight: Date | number,
) => {
  return differenceInMinutesFns(dateLeft, dateRight) % 60;
};

export const differenceInHours = (
  dateLeft: Date | number,
  dateRight: Date | number,
) => {
  return differenceInHoursFns(dateLeft, dateRight);
};

export const differenceInMinutes = (
  dateLeft: Date | number,
  dateRight: Date | number,
) => {
  return differenceInMinutesFns(dateLeft, dateRight);
};

export const differenceInMilliseconds = (
  dateLeft: Date | number,
  dateRight: Date | number,
) => {
  return differenceInMillisecondsFns(dateLeft, dateRight);
};

export const disableDatesAfterToday = (current: Date) => {
  return current && current > endOfDay(new Date());
};

const eighteenYearsAgo = subYears(new Date(), 18);

export const disableDatesTillAdult = (current: Date) => {
  return (
    (current && current > endOfDay(new Date())) ||
    (current && current > eighteenYearsAgo)
  );
};

export const parseDate = (dateString: string, finalFormat: string) => {
  return parse(dateString, finalFormat, new Date());
};

export const msToDuration = (time: number | Date) => {
  return intervalToDuration({ start: 0, end: time });
};

export const asSeconds = ({ unixTimestampMs }: { unixTimestampMs: number }) => {
  return Math.floor(unixTimestampMs / 1000);
};

export const minutesToMillis = (minutes: number) => {
  return minutes * 60000;
};

export const displayDurationISO = (d: string): string => {
  const seconds = toSeconds(parseISO(d));
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });
  const days = duration.days || 0;
  const hours = duration.hours || 0;
  const minutes = duration.minutes || 0;
  return `
  ${days > 0 ? Math.floor(days).toFixed(0) + "d " : ""}
  ${String(hours).padStart(2, "0")}:
  ${String(minutes).padStart(2, "0")}`;
};

export const displayDuration = (timeInSeconds: number): string => {
  const duration = intervalToDuration({
    start: 0,
    end: timeInSeconds * 1000,
  });
  const days = duration.days || 0;
  const hours = duration.hours || 0;
  const minutes = duration.minutes || 0;
  const seconds = duration.seconds || 0;

  return `
  ${days > 0 ? Math.floor(days).toFixed(0) + "d " : ""}
  ${String(hours).padStart(2, "0")} :
  ${String(minutes).padStart(2, "0")} :
  ${String(seconds).padStart(2, "0")}`;
};
