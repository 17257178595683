import { logError } from "@integration/errors/bugsnag/csr";

const executeSafely = (fn: () => void) => {
  try {
    fn();
  } catch (e) {
    logError(e as Error | string);
  }
};

export const cio = {
  identify: (id: string) => {
    executeSafely(() => {
      if (!window._cio) {
        return;
      }
      window._cio.identify({
        id,
      });
    });
  },
  pageView: () => {
    executeSafely(() => {
      if (!window._cio) {
        return;
      }
      window._cio.page(document.location.href, {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  },
  track: ({
    eventName,
    metadata,
  }: {
    eventName: string;
    metadata?: Record<string, string>;
  }) => {
    executeSafely(() => {
      if (!window._cio) {
        return;
      }
      window._cio.track(eventName, metadata);
    });
  },
};
