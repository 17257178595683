import Link from "next/link";
import Arrow from "@public/svg/arrow.svg";
import styles from "./index.module.scss";

interface Props {
  text: string;
  linkTo: string;
  showArrowIcon: boolean;
}

export const GoBackButton = ({ text, linkTo, showArrowIcon }: Props) => {
  return (
    <Link href={linkTo}>
      <div className={styles.wrapper}>
        {showArrowIcon && <Arrow />}
        <span className={styles.text}>{text}</span>{" "}
      </div>
    </Link>
  );
};
