/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { posthog } from "posthog-js";
import { cio } from "@integration/customer-io/csr";
import { onPageView as reportPageViewToGoogleAds } from "@integration/google-ads/csr";

const IS_DEV = process.env.NODE_ENV === "development";

export const trackPageView = () => {
  if (IS_DEV) {
    console.log(`page-view`);
    return;
  }
  posthog.capture("$pageview");
  cio.pageView();
  const { gtag } = window;
  if (gtag) {
    gtag("event", "page_view", {
      page_title: document.title,
      page_location: window.location.pathname,
    });
  }
  const fbq = (window as any).fbq;
  if (fbq) {
    fbq("track", "PageView");
  }
  reportPageViewToGoogleAds();
};

export const publishGoogleAnalyticsEvent = ({
  eventCategory,
  eventAction,
}: {
  eventCategory: string;
  eventAction: string;
}) => {
  if (IS_DEV) {
    console.log(
      `google-analytics: eventCategory=${eventCategory}; eventAction=${eventAction}`,
    );
  }
  const gtag = (window as any).gtag;
  if (gtag) {
    gtag("event", eventAction.toLowerCase(), {
      event_category: eventCategory,
      debug_mode: true, // this makes events available in the DebugView
    });
  }
};

export const publishFacebookEvent = ({ event }: { event: string }) => {
  if (IS_DEV) {
    console.log(`facebook: event=${event}`);
  }
  const fbq = (window as any).fbq;
  if (fbq) {
    fbq("trackCustom", event);
  }
};

export const identify = ({ userId }: { userId: string }) => {
  posthog.identify(userId);
  cio.identify(userId);
};
