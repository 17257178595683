import React, { FC } from "react";
import Image from "next/legacy/image";
import { Form, Rate } from "antd";
import CalendarIcon from "@public/svg/calendar.svg";
import { Button } from "src/components/atoms/Button";
import { DatePicker } from "src/components/atoms/DatePickerFns";
import {
  disableDatesAfterToday,
  formatDate,
  PICKER_DATE_FORMAT,
} from "@utils/date-time/common";
import styles from "./index.module.scss";

export type DateOfBirthFormOutput = {
  date: string;
  question: number;
};

const styleConfig = {
  backgroundColor: "#292F37",
  color: "rgba(255, 255, 255, 0.7)",
  height: "55px",
  borderRadius: "10px",
};

export interface Props {
  onSubmit: (values: DateOfBirthFormOutput) => void;
  onCancel: () => void;
}

export const DateOfBirthForm: FC<Props> = ({ onCancel, onSubmit }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.close}>
        <Image
          src={"/svg/close.svg"}
          alt={"close image"}
          height={18}
          width={18}
          onClick={onCancel}
        />
      </div>

      <div className={styles.cardWrapper}>
        <span className={styles.cardDesc} data-testid="check-form-text">
          Pirms pieslēdzies nodarbībai mums ir svarīgi zināt Jūsu viedokli.
        </span>
        <Form
          style={{ paddingTop: "15px" }}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item
            label="Lūdzu novērtējiet Jūsu pieredzi ar Codelex līdz šim ?"
            name="question"
            rules={[
              {
                required: true,
                message: "Ievadi savu atbildi",
              },
            ]}
          >
            <Rate style={{ color: "#00fca3" }} data-testid="rate-star-input" />
          </Form.Item>

          <Form.Item
            label="Izvēlies savu dzimšanas dienas datumu"
            name="date"
            getValueFromEvent={(onChange) =>
              formatDate(onChange, PICKER_DATE_FORMAT)
            }
            valuePropName={"date"}
            rules={[
              {
                required: true,
                message: "Ievadi pareizu savu dzimšanas dienas datumu",
              },
            ]}
          >
            <DatePicker
              style={styleConfig}
              placeholder="Izvēlies datumu"
              showToday={false}
              disabledDate={disableDatesAfterToday}
              suffixIcon={
                <div className={styles.calendarIconWrapper}>
                  <CalendarIcon />
                </div>
              }
            />
          </Form.Item>
          <div className={styles.buttonWrapper}>
            <Button htmlType="submit" data-testid="personal-details-form">
              {" "}
              Apstiprināt un doties uz nodarbību
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
