import React from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import styles from "./index.module.scss";

export const Profile = () => {
  return (
    <Link href={"/my-account"}>
      <div className={styles.wrapper}>
        <div className={styles.desc} data-testid="user-profile-badge-title">
          Mans konts
        </div>
        <div className={styles.avatarWrapper}>
          <Image
            src={"/svg/avatar.svg"}
            alt={"avatar image"}
            height={19}
            width={19}
          />
        </div>
      </div>
    </Link>
  );
};
