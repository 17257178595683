import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { print } from "graphql/language/printer";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { leaveBreadcrumb, logError } from "@integration/errors/bugsnag/csr";

type NetworkError = { statusCode: number };

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const context = operation.getContext();
  if (graphQLErrors) {
    leaveBreadcrumb({
      message: "GraphQL error",
      metadata: {
        query: print(operation.query),
        variables: operation.variables,
        context,
      },
    });
    logError(graphQLErrors);
  }
  if (networkError && Object.keys(networkError).length > 0) {
    const { statusCode } = networkError as NetworkError;
    if (typeof window === "undefined") {
      return;
    }
    const { location } = window;
    if (statusCode === 401) {
      location.replace("/sign-out");
      return;
    }
    leaveBreadcrumb({
      message: "Request failed",
      metadata: {
        query: print(operation.query),
        variables: operation.variables,
        statusCode,
        pathname: location.pathname,
        context,
      },
    });
    logError(networkError);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: { max: 3 },
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    retryLink,
    new HttpLink({
      uri: "/api/account/next-graphql",
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: { fetchPolicy: "network-only" },
  },
});

export { client };
