import { useMutation, gql } from "@apollo/client";
import { client } from "@integration/next-account-api";

type PersonalInformationInputVariables = {
  input: PersonalInformationVariables;
};

export type PersonalInformationVariables = {
  gender?: "MALE" | "FEMALE" | "UNKNOWN";
  dateOfBirth?: string;
  skillLevel?: number;
};

export const useAddPersonalInformation = () => {
  return useMutation<unknown, PersonalInformationInputVariables>(
    gql`
      mutation ($input: PersonalInformationInput!) {
        addPersonalInformation(input: $input)
      }
    `,
    { client },
  );
};
