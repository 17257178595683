import { getCookie } from "cookies-next";
import { ACCOUNT_ID } from "../constants";

export const getAccountId = (): string | undefined => {
  const cookie = getCookie(ACCOUNT_ID);
  if (!cookie) {
    return;
  }
  return cookie as string;
};
